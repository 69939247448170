/**
 * Direttiva che costruisce la tab di un filtro nella barra di ricerca
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface ISearchBarTabDirectiveScope extends ng.IScope {
	libraryApplicationData: any;
	filterName: any;
	searchBarManager: any;
	currentFilter: any;
}
angular.module('app').directive("searchBarTab", (LibraryApplicationData, GlobalApplicationData, SearchBarManager) => {
	return {
		restrict: 'E',
		transclude: true,
		replace: true,
		scope: {
			filterName: '@',
			currentIndex: '=',
			filterParams: '=',
			currentFilter: '='
		},
		link: link,
		templateUrl: 'app/shared/searchBar/searchBarTab/searchBarTab.html'
	};
	function link($scope: ISearchBarTabDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento all'oggetto principale
		$scope.libraryApplicationData = LibraryApplicationData;
		
		// Collegamento al servizio che gestisce i filtri
		$scope.searchBarManager = SearchBarManager;
	}
});